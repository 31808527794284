import ReusableGrid from 'components/containers/ReusableGrid'
import Heading from 'components/molecules/Heading'
import achievements from 'consts/WhatWillYouGet.js'
import React from 'react'

const WhatWillYouGet = () => {
  const AchievementItem = achievement => (
    <div className='flex w-full flex-col items-start'>
      <div className='flex items-center gap-4'>
        {achievement.icon}
        <div className='flex flex-col'>
          <Heading postTitle={achievement.heading} className='-mt-9 text-xl font-semibold' />
          <p className='text-lg'>{achievement.subHeading}</p>
        </div>
      </div>
    </div>
  )
  return (
    <ReusableGrid className='card bg-white !p-16 !shadow-none md:py-16'>
      <div className='col-span-full flex items-center text-center'>
        <Heading title={achievements.title} heroHeading={true} className='mx-auto' />
      </div>

      <div className='col-span-full flex flex-col md:flex-row md:justify-around'>
        <div className='flex flex-col gap-4'>
          {achievements.achievements.slice(0, 5).map((achievement, i) => (
            <AchievementItem {...achievement} key={`column-${i - 0}`} />
          ))}
        </div>
        <div className='flex flex-col gap-4'>
          {achievements.achievements.slice(5, 10).map((achievement, i) => (
            <AchievementItem {...achievement} key={`column-${i - 0}`} />
          ))}
        </div>
      </div>
    </ReusableGrid>
  )
}

export default WhatWillYouGet
