import { AiOutlineSmile } from '@react-icons/all-files/ai/AiOutlineSmile'
import { BsHourglassSplit } from '@react-icons/all-files/bs/BsHourglassSplit'
import { GiTakeMyMoney } from '@react-icons/all-files/gi/GiTakeMyMoney'

// import { IoDiamond } from '@react-icons/all-files/io5/IoDiamond'

const benefits = {
  title: 'Benefits you can expect',
  benefits: [
    {
      id: 3,
      heading: 'Save More Money',
      subHeading: 'Industry leading rates!',
      icon: <GiTakeMyMoney size={52} className='text-success' />,
    },
    {
      id: 1,
      heading: 'Improved Customer Experience',
      subHeading: 'Give them your very best!',
      icon: <AiOutlineSmile size={52} className='text-info' />,
    },
    {
      id: 2,
      heading: 'Save your Previous Time',
      subHeading: 'Let us automate your workflow!',
      icon: <BsHourglassSplit size={52} className='text-error' />,
    },
  ],
}

export default benefits
