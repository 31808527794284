// import { IoDiamond } from '@react-icons/all-files/io5/IoDiamond'
import { PiConfettiLight } from '@react-icons/all-files/pi/PiConfettiLight'
import { SiGooglemeet } from '@react-icons/all-files/si/SiGooglemeet'
import { VscServerProcess } from '@react-icons/all-files/vsc/VscServerProcess'

const steps = {
  title: 'How it works?',
  steps: [
    {
      id: 1,
      heading: 'Step 1: Schedule a Meeting',
      subHeading: 'We answer all your questions and onboard you.',
      icon: <SiGooglemeet size={36} className='text-secondary' />,
    },
    {
      id: 2,
      heading: 'Step 2: We handle all the details',
      subHeading: 'We take care of everything from start to finish.',
      icon: <VscServerProcess size={36} className='text-info' />,
    },
    {
      id: 3,
      heading: 'Step 3: Run Great Events',
      subHeading: 'We help you run great events and make money.',
      icon: <PiConfettiLight size={36} className='text-success' />,
    },
  ],
}

export default steps
