// import DynamicCTA from 'components/atoms/DynamicCta'
import ReusableGrid from 'components/containers/ReusableGrid'
import ImageCarousel from 'components/molecules/ImageCarousel'
import MediaText from 'components/organisms/MediaText'
import data from 'consts/LandingCta.js'
import React from 'react'

const LandingCTA = () => {
  return (
    <ReusableGrid className='py-6 md:py-16'>
      <MediaText
        className='col-span-full'
        image={data.image}
        cta={{
          ...data,
          postTitle: data.description,
          ctaOnClick: () => {},
        }}
        // overrideCta={true}
        imageOverride={<ImageCarousel images={data.images} />}
      />
    </ReusableGrid>
  )
}

export default LandingCTA
