import ROUTES from 'consts/Routes'
import { BOOK_A_MEETING_WITH_FOUNDERS_URL } from 'consts/Urls'

const HERO = {
  // title: 'Beyond Events: Curating Experiences that Lasts a Lifetime',
  title: 'Go Beyond Events',
  subTitle: 'Create Experiences that lasts a Lifetime',
  // image: '/assets/hero/landingPartners.jpg',
  // image: '/images/senior-couple-deskop.webp',
  image: '/images/senior-couple-deskop.webp',
  images: [
    { src: '/images/couple-at-event.webp', alt: '' },
    { src: '/images/yong-kid-welcoming.webp', alt: '' },
    { src: '/images/senior-couple-deskop.webp', alt: '' },
    //  {src:'/assets/dancer.png',alt:""},
    //  {src:'/images/senior-couple-deskop.webp',alt:""},
  ],
  cta1: {
    title: 'Sign Up Now',
    href: ROUTES.SIGN_UP,
    external: false,
  },
  cta2: {
    title: 'Book a Demo',
    href: BOOK_A_MEETING_WITH_FOUNDERS_URL,
    external: true,
  },
}

export default HERO
