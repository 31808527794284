import ReusableGrid from 'components/containers/ReusableGrid'
import Heading from 'components/molecules/Heading'
import steps from 'consts/OnboardingSteps.js'
import React from 'react'

const HostOnboardingProcess = () => {
  return (
    <ReusableGrid>
      <div className='col-span-full flex items-center text-center md:col-span-5'>
        <Heading title={steps.title} className='mx-auto' />
      </div>
      <div className='col-span-full gap-2 md:col-span-6 md:col-start-7'>
        {steps.steps.map(step => (
          <div key={step.id} className='mb-5 flex w-full items-center gap-4 p-2'>
            {/* {React.cloneElement(step.icon, {
              size: 36,
            })} */}
            {step.icon}
            <div>
              <Heading postTitle={step.heading} className='-mt-6 text-2xl font-semibold' />
              <p className='text-lg '>{step.subHeading}</p>
            </div>
          </div>
        ))}
      </div>
    </ReusableGrid>
  )
}

export default HostOnboardingProcess
