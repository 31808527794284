import ReusableGrid from 'components/containers/ReusableGrid'
import Heading from 'components/molecules/Heading'
import benefits from 'consts/Benefits'
import React from 'react'

const Benefits = () => {
  return (
    <ReusableGrid className='rounded-xl bg-white py-6 md:py-16'>
      <div className='col-span-full flex items-center text-center'>
        <Heading title={benefits.title} heroHeading={true} className='mx-auto' />
      </div>

      <div className=' col-span-full flex flex-wrap items-center justify-evenly gap-4'>
        {benefits.benefits.map(benefit => (
          <div key={benefit.id} className='flex w-full flex-col items-center gap-2  p-2 md:w-max'>
            {/* {React.cloneElement(benefit.icon, {
              size: 52,
            })} */}
            {benefit.icon}
            <Heading postTitle={benefit.heading} className='-mt-6 text-2xl font-semibold' />
            <p className='text-center text-lg font-semibold'>{benefit.subHeading}</p>
          </div>
        ))}
      </div>
    </ReusableGrid>
  )
}

export default Benefits
