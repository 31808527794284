import { IoMdCheckmark } from '@react-icons/all-files/io/IoMdCheckmark'

const achievements = {
  title: "Here's what will you get",
  achievements: [
    {
      id: 1,
      heading: 'Extremely Affordable Fees',
      subHeading: "Only 6% + 20p. That's it!",
      icon: <IoMdCheckmark size={24} className='text-success' />,
    },
    {
      id: 2,
      heading: 'Thriving Marketplace & Community',
      subHeading: 'Connect and grow with like-minded event planners',
      icon: <IoMdCheckmark size={24} className='text-success' />,
    },
    {
      id: 3,
      heading: 'Extremely Fast Checkout Experience',
      subHeading: 'A 3-step, streamlined process',
      icon: <IoMdCheckmark size={24} className='text-success' />,
    },
    {
      id: 4,
      heading: 'Personalized Passes',
      subHeading: 'End untrackable tickets',
      icon: <IoMdCheckmark size={24} className='text-success' />,
    },
    {
      id: 5,
      heading: 'Built-in Scanner',
      subHeading: 'Speedy check-ins',
      icon: <IoMdCheckmark size={24} className='text-success' />,
    },
    {
      id: 6,
      heading: 'Built-in Marketing',
      subHeading: 'Direct access to all your customers',
      icon: <IoMdCheckmark size={24} className='text-success' />,
    },
    {
      id: 7,
      heading: 'Analytics',
      subHeading: 'Unpack customer behavior with ease',
      icon: <IoMdCheckmark size={24} className='text-success' />,
    },
    {
      id: 8,
      heading: 'Mobile First Design',
      subHeading: 'Optimal on mobile, sleek on desktop',
      icon: <IoMdCheckmark size={24} className='text-success' />,
    },
    {
      id: 9,
      heading: 'Automagic SEO',
      subHeading: 'SEO that effortlessly works for you',
      icon: <IoMdCheckmark size={24} className='text-success' />,
    },
    {
      id: 10,
      heading: 'AI Tools & Automations',
      subHeading: 'Embrace the future with our AI integrations',
      icon: <IoMdCheckmark size={24} className='text-success' />,
    },
  ],
}

export default achievements
