// import cn from 'clsx'
// import Image from 'components/atoms/Image'
// import Container from 'components/containers/Container'
// import GridContainer from 'components/containers/GridContainer'
import ReusableGrid from 'components/containers/ReusableGrid'
// import SectionContainer from 'components/containers/SectionContainer'
// import CTABlock from 'components/molecules/CTABlock'
// import HeroHome from 'components/organisms/HeroHome'
import Benefits from 'components/organisms/Landing/Benefits'
import FooterCta from 'components/organisms/Landing/FooterCta'
import HostOnboardingProcess from 'components/organisms/Landing/HostOnboardingProcess'
import LandingCTA from 'components/organisms/Landing/LandingCTA'
import LandingHero from 'components/organisms/Landing/LandingHero'
// import Testimonials from 'components/organisms/Landing/Testimonials'
import WhatWillYouGet from 'components/organisms/Landing/WhatWillYouGet'
// import MediaOverlay from 'components/organisms/MediaOverlay'
// import Footer from 'components/organisms/nav/Footer'
import { ROUTES } from 'consts'
import { default as eventPlanning } from 'content/EventPlanning.json'
// import { default as eventPageData } from 'content/EventPlanningAccordian.json'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

// #############################################################################
// #                           SERVER SIDE CODE                                #
// #############################################################################

// const IMAGES = [
//   {
//     url: 'https://firebasestorage.googleapis.com/v0/b/sessami-production.appspot.com/o/public%2Fpromo1_1280x1280.webp?alt=media&token=13e7980e-b067-4783-a366-a86b5e830f8d',
//     alt: 'Sessami hero ',
//   },
//   {
//     url: 'https://firebasestorage.googleapis.com/v0/b/sessami-production.appspot.com/o/public%2Fpromo2_1280x1280.webp?alt=media&token=e4d9c111-b2fc-45c7-a6cb-df23438a90da',
//     alt: 'Sessami hero ',
//   },
//   {
//     url: 'https://firebasestorage.googleapis.com/v0/b/sessami-production.appspot.com/o/public%2Fpromo3_1280x1280.webp?alt=media&token=99f34a4a-e946-4e7d-930f-806e34e9c084',
//     alt: 'Sessami hero ',
//   },
//   {
//     url: 'https://firebasestorage.googleapis.com/v0/b/sessami-production.appspot.com/o/public%2Fpromo4_1280x1280.webp?alt=media&token=9136ea83-265e-459f-9cca-ff58911bd7cf',
//     alt: 'Sessami hero ',
//   },
//   {
//     url: 'https://firebasestorage.googleapis.com/v0/b/sessami-production.appspot.com/o/public%2Fpromo5_1280x1280.webp?alt=media&token=aa7025cf-56c8-4b14-8cfe-8550868afac4',
//     alt: 'Sessami hero ',
//   },
// ]

export async function getStaticProps() {
  // Images
  const heroImage = { src: '/assets/featuresPage/localeventlive.webp' }
  const gumroad = { src: '/images/planning.webp' }

  // MediaText Props
  const mediaTextCtaData = eventPlanning.mediaTextData

  // Media Overlay
  const mediaOverlayData = eventPlanning.mediaOverlayData

  // const seo = eventPlanning.seo

  // const faqsData = HOSTFAQ

  return {
    props: {
      data: {
        heroImage,
        gumroad,
        mediaTextCtaData,
        // seo,
        mediaOverlayData,
        // faqsData,
      },
    },
  }
}

const HomePage = ({
  data: {
    // heroImage,
    // gumroad,
    // mediaTextCtaData,
    // mediaOverlayData,
    //  faqsData
  },
}) => {
  const router = useRouter()
  // const user = useSelector(s => s?.pr?.user?.auth)
  const accessToken = useSelector(s => s?.pr?.user?.auth?.stsTokenManager?.accessToken)

  useEffect(() => {
    if (accessToken) router.push(ROUTES.DASHBOARD)
  }, [router.pathname, accessToken])

  // const CTA = {
  //   title: 'Master Event Management and Planning!',
  //   postTitle: 'With less stress and less fees!',
  //   ctaText: 'Sign Up',
  // }

  return (
    <>
      <LandingHero />

      <ReusableGrid className='card bg-white !shadow-none '>
        <div
          className='hero col-span-full h-fit py-20 backdrop-blur-md'
          // style={{
          //   backgroundImage:
          //     'url(https://daisyui.com/images/stock/photo-1507358522600-9f71e620c44e.jpg)',
          // }}
        >
          {/* <div className='hero-overlay bg-base-200 card !shadow-none'></div> */}
          <div className='!md:max-w-1/2 hero-content !w-full text-center text-primary'>
            <div className=''>
              <h1 className='mb-5 text-3xl font-bold md:text-5xl'>
                The Challenge of Running Events!
              </h1>
              <p className='mb-5 text-xl'>
                Running events isn't easy! If you're here, you already know that managing events is
                challenging, with tools scattered across various platforms that don't sync up
                effectively. That's why we created Sessami—to simplify all your event planning and
                management needs. With us, experience less stress and lower fees. Our automated
                tools save you time and money, letting you focus on what you do best: running your
                business. Stand out with Sessami and enjoy the peace of mind that comes from our
                cost-effective solutions.
                {/* If you're here, then you already know that managing events
                is challenging. We have all these tools and tech yet they are distibuted across
                different platforms and don't coordinate well.
                <br />
                That's why we created Sessami—to streamline all your event planning and management
                with less stress and lower fees. We equip you with automated tools that save you
                time and money, allowing you to focus on what you do best: running your business.
                <br />
                Stand out with Sessami, and enjoy the peace of mind that comes from paying just a
                fraction of what other platforms charge. */}
              </p>
              {/* <button className='btn btn-secondary'>Get Started</button> */}
            </div>
          </div>
        </div>
      </ReusableGrid>

      <LandingCTA />

      <Benefits />
      <HostOnboardingProcess />
      <WhatWillYouGet />
      {/* <Testimonials /> */}
      <FooterCta />

      {/* <SectionContainer>
        <Container>
        
          <GridContainer className=' mt-5 items-center rounded-md p-4 md:mt-10'>
          </GridContainer>

          <GridContainer className=' mt-5 items-center rounded-md bg-base-200 p-4 md:mt-10'>
          </GridContainer>

          <GridContainer className='mt-5 items-center rounded-md p-4 md:mt-10'>
          </GridContainer>
          <GridContainer className=' mt-5 items-center rounded-md bg-base-200 p-4 md:mt-10'>
          </GridContainer>
          <GridContainer className=' mt-5 items-center rounded-md p-4 md:mt-10'>
          </GridContainer>
          <GridContainer className='mt-5 items-center rounded-md bg-base-200 px-4 py-8 md:mt-10'>
          </GridContainer>
        </Container>
      </SectionContainer> */}

      {/* <HeroHome /> */}

      {/* <SectionContainer
        className={cn(
          `md:bg-fill relative -mt-12 h-screen bg-cover bg-fixed  md:h-screen md:bg-scroll md:bg-right`
        )}>
        <div className='absolute inset-0'>
          <Container className='h-full'>
            <Image
              src={IMAGES[0].url}
              alt='Background Image'
              layout='fill'
              objectFit='cover'
              quality={100}
            />
            <GridContainer className='flex h-full items-end'>
              <CTABlock
                className={cn(
                  'col-span-full px-8 lg:col-span-6 lg:col-start-1',
                  'rounded-3xl bg-base-300/50 text-primary-content backdrop-blur-md',
                  'mb-12'
                )}
                cta={{
                  ...CTA,
                  // ctaOnClick: () => router.push(ROUTES.ROOT + '#discover'),
                }}
              />
            </GridContainer>
          </Container>
        </div>
      </SectionContainer> */}

      {/* <div
        className='hero min-h-screen'
        style={{
          backgroundImage:
            'url(https://firebasestorage.googleapis.com/v0/b/sessami-production.appspot.com/o/public%2Fpromo1_1280x1280.webp?alt=media&token=13e7980e-b067-4783-a366-a86b5e830f8d)',
        }}>
        <div className="hero-overlay bg-base-300/90 w-full h-full"></div>
        <div className='card hero-content bg-base-100/50 text-center text-neutral-content backdrop-blur-md'>
          <div className='max-w-md'>
            <h1 className='mb-5 text-5xl font-bold'>{CTA.title}</h1>
            <p className='mb-5'>{CTA.postTitle}</p>
            <button className='btn btn-primary'>Get Started</button>
          </div>
        </div>
      </div> */}
      {/* 
      <div
        className='hero min-h-screen'
        style={{
          backgroundImage:
            'url(https://firebasestorage.googleapis.com/v0/b/sessami-production.appspot.com/o/public%2Fpromo1_1280x1280.webp?alt=media&token=13e7980e-b067-4783-a366-a86b5e830f8d)',
        }}>
        <div className='hero-overlay bg-opacity-60'></div>
        <div className='hero-content text-center text-neutral-content'>
          <div className='max-w-md'>
            <h1 className='mb-5 text-5xl font-bold'>Hello there</h1>
            <p className='mb-5'>
              Provident cupiditate voluptatem et in. Quaerat fugiat ut assumenda excepturi
              exercitationem quasi. In deleniti eaque aut repudiandae et a id nisi.
            </p>
            <button className='btn btn-primary'>Get Started</button>
          </div>
        </div>
      </div> */}

      {/* <MediaOverlay
        className='mb-14 md:mb-28'
        // image={mediaOverlayData.image}
        image={IMAGES[0]}
        images={IMAGES}
        cta={{
          // ...mediaOverlayData.cta,
          ...CTA,
          // ctaOnClick: () => router.push(ROUTES.HOSTING.FOR_HOSTS),
        }}
        includeMeetScheduleLink
      /> */}

      {/* <div className='hero min-h-screen bg-base-200'>
        <div className='hero-content flex-col lg:flex-row-reverse'>
          <img src={IMAGES[0].url} className='max-w-3xl rounded-lg shadow-2xl' />
          <div>
            <h1 className='text-5xl font-bold'>{CTA.title}</h1>
            <p className='py-6'>{CTA.postTitle}</p>
            <div className='flex space-x-4'>
              <button className='btn btn-primary'>Sign Up</button>
              <button className='btn btn-secondary'>Book a Tour</button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  )
}

export default HomePage
