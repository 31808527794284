import ROUTES from './Routes'
import { BOOK_A_MEETING_WITH_FOUNDERS_URL } from './Urls'

const cta = {
  id: 1,
  title:
    '<p>👋 Hi, I am <a href="https://www.linkedin.com/in/sam-van-leer-a019b23a/" target="_blank" class="underline">Sam,</a> <br/>& I have been where you are.</a>',
  description: `
  Growing up in pubs and playing in venues across the UK, I know how valuable live events are to the local communities.<br/><br/>That's why I co-founded <a href="https://sessami.co" target="_blank" class="underline">Sessami</a> with like <a href="https://www.linkedin.com/in/luke-rose-251525145/" target="_blank" class="underline">Luke</a> & <a href="https://www.linkedin.com/in/keshavdulal" target="_blank" class="underline">Keshav</a>, a place where entrepreneurs like you can thrive. Our passion for the local "scene", bringing people together and creating lasting memories is what drives us. We offer more than just a software; we provide a community, and the support you need to take your business to the next level while avoiding the headaches of event management.`,

  // I undestand the struggle of finding the community and tools to nurture your business and creativity. That's why I co-founded <a href="https://sessami.co" target="_blank" class="underline">Sessami</a> with like <a href="https://www.linkedin.com/in/luke-rose-251525145/" target="_blank" class="underline">Luke</a> & <a href="https://www.linkedin.com/in/keshavdulal" target="_blank" class="underline">Keshav</a>, a place where entrepreneurs like you can thrive.<br/>Our passion for bringing people together and creating an environment that fosters growth, collaboration, and success is what drives us. We offer more than just a software; we provide a community, and the support you need to take your business to the next level.`,
  // image: { src: '/images/svl-on-stage.jpg', alt: 'Sam on stage' },
  image: { src: '/images/svl-selfie.jpg', alt: 'Sam on stage' },
  images: [
    // { src: '/images/svl-selfie.jpg', alt: 'Sam on stage' },
    { src: '/assets/samGuitar.webp', alt: 'Sam on stage' },
    { src: '/assets/new/Luke.webp', alt: 'Luke Rose' },
    { src: '/assets/new/Keshav.webp', alt: 'Keshav Dulal' },
  ],
  // image: { src: '/images/svl.png', alt: 'Sam on stage' },
  imagePosition: 'left',
  cta1: {
    title: 'Sign Up Now',
    href: ROUTES.SIGN_UP,
    external: false,
  },
  cta2: {
    title: 'Schedule a Meeting with Me',
    href: BOOK_A_MEETING_WITH_FOUNDERS_URL,
    external: true,
  },
}

export default cta
